import {apiSecureClient, apiClient} from '../utils/api-client'

async function create(applicationData) {
  return await apiSecureClient('loan-applications', {
    data: {...applicationData},
    method: 'POST',
  }).then(res => res)
}

async function list() {
  return await apiSecureClient('loan-applications').then(res => res)
}

async function get(uuid) {
  return await apiSecureClient(`loan-applications/${uuid}`).then(res => res)
}

async function update({data, uuid}) {
  return await apiSecureClient(`loan-applications/${uuid}`, {
    data,
    method: 'PUT',
  })
}

async function deleteDoc(uuid) {
  return apiSecureClient(`loan-application-documents/${uuid}`, {
    method: 'DELETE',
  })
}

async function deleteQuarterlyIRADoc(uuid) {
  return apiSecureClient(`quarterly-statement-uploads/${uuid}`, {
    method: 'DELETE',
  })
}

async function createManager(uuid, manager) {
  return apiSecureClient(`loan-applications/${uuid}/managers`, {
    data: manager,
    method: 'POST',
  }).then(res => res)
}

async function getManager(uuid, managerUuid) {
  return apiClient(`loan-applications/${uuid}/managers/${managerUuid}`, {
    method: 'GET',
  }).then(res => res)
}

async function updateManager(uuid, muuid, manager) {
  return apiClient(`loan-applications/${uuid}/managers/${muuid}`, {
    data: {manager},
    method: 'PUT',
  }).then(res => res)
}

async function deleteManager(uuid, muuid) {
  return apiSecureClient(`loan-applications/${uuid}/managers/${muuid}`, {
    method: 'DELETE',
  })
}

async function uploadQuarterlyIRAStatement(uuid) {
  return apiSecureClient(
    `loan-applications/${uuid}/quarterly-statment-upload`,
    {
      method: 'POST',
    },
  )
}

async function constants() {
  return apiSecureClient('loan-applications/constants')
}

export {
  create,
  deleteDoc,
  deleteQuarterlyIRADoc,
  get,
  list,
  update,
  constants,
  getManager,
  createManager,
  deleteManager,
  updateManager,
  uploadQuarterlyIRAStatement,
}
